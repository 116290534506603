import { Injectable } from '@angular/core';
import { CountryCode, CountryLanguageCodes, EnergySource, RemoteConfigParameter } from '@nx-customer-apps/shared/enums';
import { EnumDictionary, LocaleUtils, isOfCountry } from '@nx-customer-apps/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { RemoteConfigService } from '../services';
import { AuthStore } from '../state/auth/auth.store';
import { Observable, map, withLatestFrom } from 'rxjs';
import { EnergyCostLimit } from '@nx-customer-apps/shared/interfaces';

@Injectable({
    providedIn: 'root'
})
export class RollOutPolicy {
    constructor(private remoteConfig: RemoteConfigService, private translate: TranslateService, private authStore: AuthStore) {}

    /**
     * Filters for remotely enabled country codes to build options for country-language switcher.
     */
    public getCountryLanguagePairs$(): Observable<
        {
            key: string;
            locale: CountryLanguageCodes;
        }[]
    > {
        return this.authStore.countryLanguagePairs$.pipe(
            withLatestFrom(this.remoteConfig.state$, (pairs, remoteConfig) => ({ pairs, remoteConfig })),
            map(({ pairs, remoteConfig }) => {
                const supportedPairs = pairs.filter(pair => {
                    const countryCode = LocaleUtils.getCountryCode(pair.locale);
                    return remoteConfig.config[RemoteConfigParameter.CountryLanguageSwitcherByCountry].includes(countryCode);
                });
                return supportedPairs;
            })
        );
    }

    /**
     * Filters for remotely enabled country codes to build options for country dropdown.
     */
    public getOptionsForCountryDropdown(countries: CountryCode[]): CountryCode[] {
        const supportedCountries = countries.filter(country => {
            return this.remoteConfig.config.countryDropdownByCountry.includes(country);
        });
        return supportedCountries as CountryCode[];
    }

    /**
     * Determines whether the Heat Pump Planner features should be enabled/displayed in the scope of specific countries.
     */
    public hasHeatPumpPlanning(addressCountryCode: CountryCode): boolean {
        return isOfCountry(addressCountryCode, [
            CountryCode.DE,
            CountryCode.AT,
            CountryCode.BE,
            CountryCode.NL,
            CountryCode.FR,
            CountryCode.CH,
            CountryCode.PL
        ]);
    }

    /**
     * Determines whether the Quote Assist features should be enabled/displayed in the scope of specific countries.
     */
    public hasQuoteAssist(salesOrgCountryCode: CountryCode): boolean {
        return this.remoteConfig.config[RemoteConfigParameter.QuoteAssistByCountry].includes(salesOrgCountryCode);
    }

    /**
     * Determines whether the Quote Assist features should be enabled/displayed in the scope of specific countries.
     */
    public hasPartnerShop(salesOrgCountryCode: CountryCode): boolean {
        return this.remoteConfig.config[RemoteConfigParameter.PartnerShopByCountry].includes(salesOrgCountryCode);
    }

    /**
     * Determines whether the Photovoltaic Planner features should be enabled/displayed in the scope of specific countries.
     */
    public hasPhotovoltaicPlanning(addressCountryCode: CountryCode): boolean {
        return isOfCountry(addressCountryCode, [CountryCode.DE, CountryCode.AT, CountryCode.BE, CountryCode.NL, CountryCode.FR]);
    }

    /**
     * Determines whether the lock period tarriff should be enabled/displayed in the scope of specific countries.
     */
    public hasElectricityDayNightTarriff(addressCountryCode: CountryCode) {
        return this.remoteConfig.config[RemoteConfigParameter.DomesticElectricityDayNightTariffByCountry].includes(addressCountryCode);
    }

    /**
     * Determines whether the lock period tarriff should be enabled/displayed in the scope of specific countries.
     */
    public hasHeatPumpElectricityFlatRatePrice(addressCountryCode: CountryCode) {
        return this.remoteConfig.config[RemoteConfigParameter.HeatPumpElectricityDayNightTariffByCountry].includes(addressCountryCode);
    }

    /**
     * Determines whether the lock period tarriff should be enabled/displayed in the scope of specific countries.
     */
    public hasHeatPumpElectricityDayNightTarriff(addressCountryCode: CountryCode) {
        return this.remoteConfig.config[RemoteConfigParameter.HeatPumpElectricityDayNightTariffByCountry].includes(addressCountryCode);
    }

    /**
     * Determines whether the lock period tarriff should be enabled/displayed in the scope of specific countries.
     */
    public hasLockPeriodTarriff(addressCountryCode: CountryCode) {
        return this.remoteConfig.config[RemoteConfigParameter.HeatPumpElectricityBlockTimeByCountry].includes(addressCountryCode);
    }

    /**
     * Determines whether the lock period tarriff input should be editable or disabled in the scope of specific countries.
     */
    public hasLockPeriodTarriffDisabled(addressCountryCode: CountryCode) {
        return this.remoteConfig.config[RemoteConfigParameter.HeatPumpElectricityBlockTimeDisabledByCountry].includes(addressCountryCode);
    }

    public hasSeparateHeatPumpEnergyPrice(addressCountryCode: CountryCode) {
        return (
            this.hasHeatPumpElectricityFlatRatePrice(addressCountryCode) ||
            this.hasHeatPumpElectricityDayNightTarriff(addressCountryCode) ||
            this.hasLockPeriodTarriff(addressCountryCode)
        );
    }

    /**
     * Determines whether a living area calculation is enabled/displayed in the scope of specific countries.
     */
    public hasLivingAreaCalcuatiion(addressCountryCode: CountryCode) {
        return !isOfCountry(addressCountryCode, CountryCode.BE);
    }

    /**
     * Determines whether a building hull calculation is enabled/displayed in the scope of specific countries.
     */
    public hasBuildingHullCalculation(addressCountryCode: CountryCode) {
        return !isOfCountry(addressCountryCode, CountryCode.BE);
    }

    /**
     * Determines whether a device calculation is enabled/displayed in the scope of specific countries.
     */
    public hasDeviceCalculation(addressCountryCode: CountryCode) {
        return this.remoteConfig.config[RemoteConfigParameter.DeviceCalculationByCountry].includes(addressCountryCode);
    }

    /**
     * Determines a deafult air exchange rate in the scope of specific countries.
     */
    public getDefaultAirExchangeRate(addressCountryCode: CountryCode): number | undefined {
        const rates: Partial<EnumDictionary<CountryCode, number>> = {
            [CountryCode.BE]: 0.24
        };
        return rates[addressCountryCode];
    }

    /**
     * Determines a title for energy standard accordion in the scope of specific countries.
     */
    public getEnergyStandardTitle(addressCountryCode: CountryCode): string | undefined {
        const titles: Partial<EnumDictionary<CountryCode, string>> = {
            [CountryCode.BE]: 'HEATLOAD.ENERGY_STANDARD.DEFAULT_STANDARD.TITLE'
        };
        return titles[addressCountryCode];
    }

    /**
     * Determines a description for energy standard accordion in the scope of specific countries.
     */
    public getEnergyStandardDescrition(addressCountryCode: CountryCode): string | undefined {
        const descriptions: Partial<EnumDictionary<CountryCode, string>> = {
            [CountryCode.BE]: 'HEATLOAD.ENERGY_STANDARD.DEFAULT_STANDARD.DESCRIPTION'
        };
        return descriptions[addressCountryCode];
    }

    /**
     * Determines energy costs min and max values and separatorLimit in the scope of specific countries.
     */
    public getEnergyCostsLimits(addressCountryCode: CountryCode): Partial<EnumDictionary<EnergySource, EnergyCostLimit>> {
        const defaultLimits: Partial<EnumDictionary<EnergySource, EnergyCostLimit>> = {
            [EnergySource.HeatingOil]: { min: 0.01, max: 10, separatorLimit: '10' },
            [EnergySource.Gas]: { min: 0.01, max: 1, separatorLimit: '1' },
            [EnergySource.Electricity]: { min: 0.1, max: 1, separatorLimit: '1' },
            [EnergySource.HeatPumpElectricity]: { min: 0.1, max: 1, separatorLimit: '1' },
            [EnergySource.Pellet]: { min: 50, max: 1000, separatorLimit: '1000' },
            [EnergySource.Softwood]: { min: 50, max: 1000, separatorLimit: '1000' },
            [EnergySource.Hardwood]: { min: 50, max: 1000, separatorLimit: '1000' }
        };

        const CzechCoronaLimits: Partial<EnumDictionary<EnergySource, EnergyCostLimit>> = {
            [EnergySource.HeatingOil]: { min: 0.01, max: 100, separatorLimit: '100' },
            [EnergySource.Gas]: { min: 0.01, max: 100, separatorLimit: '100' },
            [EnergySource.Electricity]: { min: 0.1, max: 100, separatorLimit: '100' },
            [EnergySource.HeatPumpElectricity]: { min: 0.1, max: 100, separatorLimit: '100' },
            [EnergySource.Pellet]: { min: 50, max: 10000, separatorLimit: '10000' },
            [EnergySource.Softwood]: { min: 50, max: 10000, separatorLimit: '10000' },
            [EnergySource.Hardwood]: { min: 50, max: 10000, separatorLimit: '10000' }
        };

        const PolishZlotyLimits: Partial<EnumDictionary<EnergySource, EnergyCostLimit>> = {
            [EnergySource.HeatingOil]: { min: 0.01, max: 100, separatorLimit: '100' },
            [EnergySource.Gas]: { min: 0.01, max: 100, separatorLimit: '100' },
            [EnergySource.Electricity]: { min: 0.1, max: 100, separatorLimit: '100' },
            [EnergySource.HeatPumpElectricity]: { min: 0.1, max: 100, separatorLimit: '100' },
            [EnergySource.Pellet]: { min: 50, max: 10000, separatorLimit: '10000' },
            [EnergySource.Softwood]: { min: 50, max: 10000, separatorLimit: '10000' },
            [EnergySource.Hardwood]: { min: 50, max: 10000, separatorLimit: '10000' }
        };

        const limits: Partial<EnumDictionary<CountryCode, object>> = {
            [CountryCode.CZ]: CzechCoronaLimits,
            [CountryCode.PL]: PolishZlotyLimits
        };

        return limits[addressCountryCode] || defaultLimits;
    }
}
