import { PlanningGetById, ProjectResponseGetById, VariantGetById } from '@nx-customer-apps/api-planning-projects';
import { ApplicationId } from '@nx-customer-apps/shared/enums';

export class ProjectUtils {
    static getAllPlanningsFromVariants(project: ProjectResponseGetById, variantId?: string): PlanningGetById[] | undefined {
        const allPlanningsGroupedByVariants = project.variants
            .filter(variant => (variantId ? variant.id === variantId : true))
            .map(variant => variant.plannings)
            .filter(plannings => Boolean(plannings)) as PlanningGetById[][];

        const allPlannings = allPlanningsGroupedByVariants.reduce((acc, cur) => {
            acc?.push(...cur);
            return acc;
        }, []);

        return allPlannings;
    }

    static getPlanningsWithProductsFromVariants(project: ProjectResponseGetById, variantId?: string): PlanningGetById[] | undefined {
        const allPlannings = ProjectUtils.getAllPlanningsFromVariants(project, variantId);
        return allPlannings?.filter(planning => planning?.products?.length);
    }

    static getSelectedVariant(project: ProjectResponseGetById): VariantGetById | undefined {
        return project?.variants?.find(variant => variant.isSelected);
    }

    static getAllPlanningsFromAllVariantsByAppId(
        project: ProjectResponseGetById,
        applicationId: ApplicationId
    ): (PlanningGetById | undefined)[] {
        const allPlanningFromAllVariants = project.variants
            .map(variant => variant.plannings)
            .filter(plannings => plannings?.length)
            .flat(1);

        const variantsByAppId = allPlanningFromAllVariants.filter(planning => planning?.applicationId === applicationId);

        return variantsByAppId;
    }

    static getPlanningsWithProductsFromSelectedVariant(project: ProjectResponseGetById): PlanningGetById[] | undefined {
        const selectedVariant = ProjectUtils.getSelectedVariant(project);
        return selectedVariant?.plannings?.filter(planning => planning?.products?.length);
    }

    static getPlanningFromSelectedVariant(project: ProjectResponseGetById, applicationId: ApplicationId): PlanningGetById | undefined {
        return ProjectUtils.getPlanningsWithProductsFromSelectedVariant(project)?.find(
            planning => planning['applicationId'] === applicationId
        );
    }

    static getPlanningFromSelectedVariantById(project: ProjectResponseGetById, planningId: string): PlanningGetById | undefined {
        return ProjectUtils.getPlanningsWithProductsFromSelectedVariant(project)?.find(planning => planning.id === planningId);
    }

    static hasPlanningsWithProductsAmongAllVariants(project: ProjectResponseGetById): boolean {
        const plannings = ProjectUtils.getPlanningsWithProductsFromVariants(project);
        return plannings ? plannings.length > 0 : false;
    }
}
