/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Readable } from '../../models/readable';

export interface ReportsControllerPdfReportGeneration$Params {

/**
 * ISO standard Alpha-2 language code.
 *     Language code to be used for report generation. The language can be different from the project language code.
 *     If the parameter is not provided the project language code is used.
 */
  languageCode?: 'cs' | 'de' | 'en' | 'es' | 'fr' | 'nl' | 'pl' | 'sk';

/**
 * The ID of the project which data will be used as a content for PDF report.
 */
  projectId: string;

/**
 * The type of the report that will be generated.
 */
  reportType: 'PITCH_DECK' | 'VIGUIDE_PLANNING_REPORT';

/**
 * Optional query parameter(s) defining the set of sections that will be selected when generating the PDF report.
 *     When the sections list is not provided, the report will contain all possible sections.
 */
  sections?: Array<string>;
}

export function reportsControllerPdfReportGeneration(http: HttpClient, rootUrl: string, params: ReportsControllerPdfReportGeneration$Params, context?: HttpContext): Observable<StrictHttpResponse<Readable>> {
  const rb = new RequestBuilder(rootUrl, reportsControllerPdfReportGeneration.PATH, 'get');
  if (params) {
    rb.query('languageCode', params.languageCode, {});
    rb.query('projectId', params.projectId, {});
    rb.query('reportType', params.reportType, {});
    rb.query('sections', params.sections, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/pdf', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Readable>;
    })
  );
}

reportsControllerPdfReportGeneration.PATH = '/planning-projects/v1/reports/pdf';
